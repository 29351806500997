<template>
  <div class="money-unit" :style="styleData">
    <p>| {{ moneyData|moneyUnit }}</p>
    <p>{{ moneyData|formatMoney }}</p>
    <p>{{ moneyData|numberDX2 }}</p>
  </div>
</template>

<script>
export default {
  props: {
    moneyData: {
      type: [Number, String],
      default: () => ('')
    },
    styleData: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style scoped>
  .money-unit{
    min-height: 60px;
    line-height: 20px;
    font-size: 14px;
  }
</style>
