<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="template-detail">
    <template v-for="(item, index) in detailConfig">
      <div v-if="item.title" :key="item.title" class="template-detail-title clear">
        <span class="leftLine left">&nbsp;</span>
        <span v-if="item.titleShowTooltip" class="rightText left">
          <el-tooltip effect="dark" placement="right">
            <template slot="content">
              // eslint-disable-next-line vue/no-v-html
              <div :class="item.titleTooltipClassName" :style="item.titleTooltipStyle" v-html="item.titleTooltip" />
            </template>
            <span class="tips-txt">
              {{ item.title }}<i
                class="br-iconfont iconfont icon-gantanhao"
                style="margin-left: 6px;font-size: 14px;color: #409EFF"
              />
            </span>
          </el-tooltip>
        </span>
        <span v-else class="rightText left">{{ item.title }}</span>
        <span v-if="item.isShowMore" class="more left" @click="showHide(item)">
          {{ item.isShow?'收起':'展开' }}
          <i v-if="item.isShow" class="br-iconfont iconfont icon-arrow-up" />
          <i v-else class="br-iconfont iconfont icon-arrow-prev" />
        </span>
        <span v-if="item.isShowJump" class="right jump-txt" @click="item.jumpClick(item)">{{ item.jumpTxt }}<i class="iconfont icon icon-arrow-right" /></span>
        <span v-if="item.isShowJumpArr" class="right jump-txt-box">
          <span v-for="(it,i) in item.jumpArr" :key="i" class="jump-txt" @click="it.jumpClick(it)">{{ it.jumpTxt }} <span v-if="i!=item.jumpArr.length-1">,</span></span>
          <i class="iconfont icon icon-arrow-right" />
        </span>
      </div>

      <template v-if="item.isShowMore&&item.isShow||!item.isShowMore">
        <detailLiTemplate v-if="item.dataType==='list'" :key="item.dataType" :item="item" />
        <formItemTemplate v-for="(ite,i) in item.dataList" v-else-if="item.dataType==='form'" :key="ite.value+i" :item="ite" :form-params="formParams" />
        <tableTemplate
          v-else-if="item.dataType==='table'"
          :key="index"
          :table-style="item.tableStyle || 'width:90%;margin:auto'"
          :item="item"
        />
        <template v-else />
      </template>
    </template>
    <slot name="operate" />
  </div>
</template>
<script>
import tableTemplate from './tableTemplate.vue'
import formItemTemplate from './formItemTemplate.vue'
import detailLiTemplate from './detailLiTemplate.vue'
export default {
  components: { formItemTemplate, detailLiTemplate, tableTemplate },
  props: {
    detailConfig: {
      type: Array,
      default: () => ([])
    },
    formParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dealDetailConfig: []
    }
  }
  // computed: {
  //   dealDetailConfig() {
  //     return this.detailConfig.filter(item => !item.hideFunc)
  //   }
  // },
  // watch: {
  //   detailConfig: {
  //     handler(newV) {
  //       this.dealDetailConfig = newV
  //     },
  //     deep: true
  //   }
  // }
}
</script>
<style lang="scss">
  .template-detail {
    padding-top: 10px;
    .title {
      font-weight: 400;
      padding: 13px 18px;
      margin: 0 -18px;
      font-size: 16px;
      line-height: 22px;
      border-bottom: 1px solid #EEF1F4;

      .tips-txt {
        cursor: pointer;
        font-weight: 400;
        font-size: 14px;
        width: max-content;
      }
    }

    .template-detail-title {
      height: 20px;
      line-height: 20px;
      margin-bottom: 10px;
      position: relative;

      .leftLine {
        width: 3px;
        height: 20px;
        display: inline-block;
        background: #4da1ff;
      }

      .rightText {
        font-size: 14px;
        color: #333;
        padding-left: 12px;
        .tips-txt{
          padding: 0;
          font-weight: 400;
          font-size: 14px;
        }
      }

      &.sub-title {
        font-size: 14px;
        color: #666666;

        .leftLine {
          background: orange;
          height: 15px;
          vertical-align: middle;
        }

        .rightText {
          font-size: 14px;
          vertical-align: middle;
        }
      }

      .more {
        font-size: 14px;
        padding-left: 12px;
        cursor: pointer;
        color: #409eff;

        .br-iconfont {
          -webkit-transition: font-size 0.25s linear, width 0.25s linear;
          -moz-transition: font-size 0.25s linear, width 0.25s linear;
          transition: font-size 0.25s linear, width 0.25s linear;
          font-size: 14px;
        }

        &:hover {
          .br-iconfont {
            font-size: 16px;
          }
        }
      }

      .jump-txt{
        color: #4DA1FF;
        cursor: pointer;
        font-size: 15px;
      }
      .jump-txt-box{
        i{
          color: #4DA1FF;
          cursor: pointer;
          font-size: 15px;
        }
      }

    }

    .add-info-btn {
      text-align: right;
      margin: -30px 0 10px;
    }

    .basic-information {
      li {
        float: left;
        width: 30%;
        font-size: 14px;
        color: #606266;
        padding-bottom: 10px;
        margin-right: 10px;
        line-height: 20px;

        .li-label {
          text-align: right;
          min-width: 112px;
          width: 140px;
          font-weight: 600;
          padding-right: 4px;
        }

        .br-input {
          width: 65%;
        }
        &.width100{
          width: 100%;
        }
        &.width50{
          width: 48%;
        }
      }
    }

    .down-file {
      cursor: pointer;
      color: #409eff;
    }

    .sub-module {
      padding-left: 30px;
    }

    .button-group {
      text-align: center;
    }
  }

  @media screen and (max-width: 1200px) {
    .template-detail {
      .basic-information {
        li {
          width: 46%;
        }
      }
    }
  }
  @media screen and (min-width: 1630px) {
    .template-detail {
      .basic-information {
        li {
          width: 24%;
        }
      }
    }
  }
</style>
