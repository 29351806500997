<template>
  <el-form-item
    v-if="item.ifFunc===undefined||item.ifFunc===null?true:item.ifFunc()"
    :ref="type==='list'?item.ref:item.ref+scopeIndex"
    :prop="type==='list'? item.prop : item.listName+'.' + scopeIndex + '.'+item.prop"
    :rules="item.rules"
    :class="item.itemClassName"
    :label="item.noLable?'':item.label"
    :label-width="item.labelWidth"
  >
    <template v-if="item.infoType&&item.infoType==='info'">
      <infoTemplate :item="item" class-name="formInfo" />
    </template>

    <el-popover
      v-else-if="item.type==='money'"
      placement="top-start"
      width="auto"
      style="min-width: 300px;max-width: 800px;"
      trigger="focus"
    >
      <moneyUnit :money-data="formParams[item.value]" />
      <el-input
        :ref="item.inputRef"
        slot="reference"
        v-model.trim="formParams[item.value]"
        class="br-input"
        :class="item.className"
        size=""
        :clearable="!item.noClearable"
        :disabled="item.disabled === true || (item.disabledFn===undefined||item.disabledFn===null?false: item.disabledFn(formParams))"
        oninput="!/^(\d+\.?)?\d{0,2}$/.test(this.value)?(this.value=this.value.substring(0, this.value.length-1)): ''"
        :placeholder="item.placeholder||'请输入'+item.label"
        @clear="(event)=>{if(item.inputClear) item.inputClear(event,formParams,scopeIndex)}"
        @input="(event)=>{if(item.inputInput) item.inputInput(event,formParams,scopeIndex)}"
        @change="inputNumChange($event,formParams,item.prop,item.inputChange)"
      />
    </el-popover>

    <el-input
      v-else-if="item.type==='rate'"
      :ref="item.inputRef"
      v-model.trim="formParams[item.value]"
      class="br-input"
      :class="item.className"
      size=""
      :clearable="!item.noClearable"
      :disabled="item.disabled === true || (item.disabledFn===undefined||item.disabledFn===null?false: item.disabledFn(formParams))"
      oninput="!/^(\d+\.?)?\d{0,3}$/.test(this.value)?(this.value=this.value.substring(0, this.value.length-1)): ''"
      :placeholder="item.placeholder||'请输入'+item.label"
      @clear="(event)=>{if(item.inputClear) item.inputClear(event,formParams,scopeIndex)}"
      @input="(event)=>{if(item.inputInput) item.inputInput(event,formParams,scopeIndex)}"
      @change="inputNumChange($event,formParams,item.prop,item.inputChange)"
    />

    <el-input
      v-else-if="item.type==='number'"
      :ref="item.inputRef"
      v-model.trim="formParams[item.value]"
      class="br-input"
      :class="item.className"
      size="mini"
      :clearable="!item.noClearable"
      :disabled="item.disabled === true || (item.disabledFn===undefined||item.disabledFn===null?false: item.disabledFn(formParams))"
      oninput="!/^(\d+)$/.test(this.value)?(this.value=this.value.substring(0, this.value.length-1)): ''"
      :placeholder="item.placeholder||'请输入'+item.label"
      @clear="(event)=>{if(item.inputClear) item.inputClear(event,formParams,scopeIndex)}"
      @input="(event)=>{if(item.inputInput) item.inputInput(event,formParams,scopeIndex)}"
      @change="inputNumChange($event,formParams,item.prop,item.inputChange)"
    />

    <el-input
      v-else-if="item.type === 'input'"
      :ref="item.inputRef"
      v-model.trim="formParams[item.value]"
      class="br-input"
      :class="item.className"
      size="mini"
      :clearable="!item.noClearable"
      :disabled="item.disabled === true || (item.disabledFn===undefined||item.disabledFn===null?false: item.disabledFn(formParams))"
      :maxlength="item.maxLength"
      :placeholder="item.placeholder||'请输入'+item.label"
      @clear="(event)=>{if(item.inputClear) item.inputClear(event,formParams,scopeIndex)}"
      @input="(event)=>{if(item.inputInput) item.inputInput(event,formParams,scopeIndex)}"
      @change="(event)=>{if(item.inputChange) item.inputChange(event,formParams,scopeIndex)}"
    />

    <el-input
      v-else-if="item.type==='textarea'"
      :ref="item.inputRef"
      v-model.trim="formParams[item.value]"
      class="br-textarea"
      :class="item.className"
      size="mini"
      :clearable="!item.noClearable"
      type="textarea"
      :autosize="{ minRows: 2, maxRows: 6}"
      show-word-limit
      :disabled="item.disabled === true || (item.disabledFn===undefined||item.disabledFn===null?false: item.disabledFn(formParams))"
      :maxlength="item.maxLength"
      :placeholder="item.placeholder||'请输入'+item.label"
      @clear="(event)=>{if(item.inputClear) item.inputClear(event,formParams,scopeIndex)}"
      @change="(event)=>{if(item.inputChange) item.inputChange(event,formParams,scopeIndex)}"
    />

    <el-autocomplete
      v-else-if="item.type==='autocomplete'"
      :ref="item.inputRef"
      v-model="formParams[item.value]"
      class="br-input"
      :class="item.className"
      size="mini"
      :clearable="!item.noClearable"
      :disabled="item.disabled === true || (item.disabledFn===undefined||item.disabledFn===null?false: item.disabledFn(formParams))"
      :fetch-suggestions="item.querySearch"
      :trigger-on-focus="item.triggerOnFocus"
      :placeholder="item.placeholder||'请输入'+item.label"
      @clear="(event)=>{if(item.inputClear) item.inputClear(event,formParams,scopeIndex)}"
      @select="item.selectFunc"
    />

    <el-select
      v-else-if="item.type === 'select'"
      :ref="item.inputRef"
      v-model="formParams[item.value]"
      class="br-select"
      :class="item.className"
      size="mini"
      :clearable="!item.noClearable"
      filterable
      :multiple="item.multiple"
      :placeholder="item.placeholder||'请选择'+item.label"
      :disabled="item.disabled === true || (item.disabledFn===undefined||item.disabledFn===null?false: item.disabledFn(formParams))"
      @clear="(event)=>{if(item.inputClear) item.inputClear(event,formParams,scopeIndex)}"
      @focus="(event)=>{if(item.inputFocus) item.inputFocus(event,formParams,scopeIndex)}"
      @change="(event)=>{if(item.inputChange) item.inputChange(event,formParams,scopeIndex)}"
    >
      <el-option
        v-for="itee in item.selectData"
        :key="itee.code"
        :label="itee.name"
        :value="itee.code"
      />
    </el-select>
    <el-select
      v-else-if="item.type === 'selectCode'"
      :ref="item.inputRef"
      v-model="formParams[item.value]"
      class="br-select"
      :class="item.className"
      size="mini"
      :clearable="!item.noClearable"
      filterable
      :multiple="item.multiple"
      :placeholder="item.placeholder||'请选择'+item.label"
      :disabled="item.disabled === true || (item.disabledFn===undefined||item.disabledFn===null?false: item.disabledFn(formParams))"
      @clear="(event)=>{if(item.inputClear) item.inputClear(event,formParams,scopeIndex)}"
      @focus="(event)=>{if(item.inputFocus) item.inputFocus(event,formParams,scopeIndex)}"
      @change="(event)=>{if(item.inputChange) item.inputChange(event,formParams,scopeIndex)}"
    >
      <el-option
        v-for="itee in $select(item.selectCode)"
        :key="itee.value"
        :label="itee.text"
        :value="itee.value"
      />
    </el-select>

    <el-select
      v-else-if="item.type === 'selectTree'"
      :ref="item.inputRef"
      v-model="formParams[item.value]"
      class="br-select tree-select"
      :class="item.className"
      size="mini"
      :clearable="!item.noClearable"
      :popper-append-to-body="false"
      :disabled="item.disabled === true || (item.disabledFn===undefined||item.disabledFn===null?false: item.disabledFn(formParams))"
      :placeholder="item.placeholder||'请选择'+item.label"
      @clear="(event)=>{if(item.inputClear) item.inputClear(event,formParams,scopeIndex)}"
      @change="(event)=>{if(item.inputChange) item.inputChange(event,formParams,scopeIndex)}"
    >
      <el-option value="null" style="height: auto;">
        <el-tree
          :data="item.selectData"
          :node-key="item.nodeKey"
          :props="item.defaultProps"
          @node-click="onLeavesClick($event,item,item.onLeavesClick)"
        />
      </el-option>
    </el-select>

    <el-date-picker
      v-else-if="item.type === 'date'"
      :ref="item.inputRef"
      v-model="formParams[item.value]"
      type="date"
      class="br-date"
      :class="item.className"
      size="mini"
      :clearable="!item.noClearable"
      :disabled="item.disabled === true || (item.disabledFn===undefined||item.disabledFn===null?false: item.disabledFn(formParams))"
      :picker-options="item.dateOptions?item.dateOptions(formParams,scopeIndex):{}"
      :value-format="item.valueFormat||'timestamp'"
      :placeholder="item.placeholder||'请选择'+item.label"
      @clear="(event)=>{if(item.inputClear) item.inputClear(event,formParams,scopeIndex)}"
      @change="(event)=>{if(item.inputChange) item.inputChange(event,formParams,scopeIndex)}"
    />

    <el-date-picker
      v-else-if="item.type === 'daterange'"
      :ref="item.inputRef"
      v-model="formParams[item.value]"
      type="daterange"
      :clearable="!item.noClearable"
      class="br-datepicker"
      :class="item.className"
      :disabled="item.disabled === true || (item.disabledFn===undefined||item.disabledFn===null?false: item.disabledFn(formParams))"
      align="right"
      size="mini"
      unlink-panels
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      :value-format="item.valueFormat||'timestamp'"
      @clear="(event)=>{if(item.inputClear) item.inputClear(event,formParams,scopeIndex)}"
      @change="dateRangeChange($event,item)"
    />

    <el-date-picker
      v-else-if="item.type === 'monthrange'"
      :ref="item.inputRef"
      v-model="formParams[item.value]"
      type="monthrange"
      :clearable="!item.noClearable"
      class="br-datepicker"
      :class="item.className"
      :disabled="item.disabled === true || (item.disabledFn===undefined||item.disabledFn===null?false: item.disabledFn(formParams))"
      align="right"
      size="mini"
      unlink-panels
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      :value-format="item.valueFormat||'timestamp'"
      @clear="(event)=>{if(item.inputClear) item.inputClear(event,formParams,scopeIndex)}"
      @change="dateRangeChange($event,item)"
    />
    <el-date-picker
      v-else-if="item.type === 'time'"
      :ref="item.inputRef"
      v-model="formParams[item.value]"
      type="datetime"
      class="br-date"
      :class="item.className"
      size="mini"
      :clearable="!item.noClearable"
      :disabled="item.disabled === true || (item.disabledFn===undefined||item.disabledFn===null?false: item.disabledFn(formParams))"
      :picker-options="item.dateOptions?item.dateOptions(formParams,scopeIndex):{}"
      :value-format="item.valueFormat||'timestamp'"
      :format="item.format||'yyyy-MM-dd HH:mm:ss'"
      :placeholder="item.placeholder||'请选择'+item.label"
      @clear="(event)=>{if(item.inputClear) item.inputClear(event,formParams,scopeIndex)}"
      @change="(event)=>{if(item.inputChange) item.inputChange(event,formParams,scopeIndex)}"
    />

    <template v-else-if="item.type === 'file'">
      <template v-if="formParams[item.fileParam]&&formParams[item.fileParam][item.value]">
        <div class="clear">
          <el-tooltip
            v-if="item.hasTooltip"
            class="left file-name"
            effect="dark"
            :content="item.hasTooltip?formParams[item.fileParam][item.value]:''"
            placement="bottom"
          >
            <span class="limitline3" @click="item.openFile(formParams[item.fileParam][item.fileId],formParams[item.fileParam][item.fileId])">
              {{ formParams[item.fileParam][item.value] }}
            </span>
          </el-tooltip>

          <span v-else class="file-name left" @click="item.openFile(formParams[item.fileParam][item.fileId],formParams[item.fileParam][item.fileId])">
            {{ formParams[item.fileParam][item.value] }}
          </span>

          <el-button
            type="text"
            :disabled="item.disabled === true || (item.disabledFn===undefined||item.disabledFn===null?false: item.disabledFn(formParams))"
            style="color:#ff0000;"
            class="left file-del-btn"
            @click="removeFile(formParams,item.fileParam)"
          >
            删除
          </el-button>
        </div>
      </template>
      <template v-else>
        <el-upload
          :ref="item.inputRef"
          v-model.trim="formParams[item.fileParam]"
          class="upload-demo"
          :show-file-list="true"
          :before-upload="(file)=>{return fileBeforeUpload(file,item.fileNumber)}"
          :action="uploadAction"
          :headers="item.fileHeaders"
          :on-success="(res,file)=>{return addFile(res,file,item.fileParam,item.addFileCb)}"
          :accept="item.accept"
          :limit="item.limit||1"
          :disabled="item.disabled === true || (item.disabledFn===undefined||item.disabledFn===null?false: item.disabledFn(formParams))"
          :on-exceed="(files, fileList)=>{return handleExceed(files, fileList,item.limit)}"
        >
          <el-tooltip class="item" effect="dark" :content="item.tooltipContent" placement="bottom">
            <el-button type="text" :disabled="item.disabled === true || (item.disabledFn===undefined||item.disabledFn===null?false: item.disabledFn(formParams))">
              上传
            </el-button>
            <div v-if="item.showTip" slot="tip" class="el-upload__tip">
              {{ item.tooltipContent }}
            </div>
          </el-tooltip>
        </el-upload>
      </template>
    </template>

    <template v-else-if="item.type==='unit'">
      <el-input
        :ref="item.inputRef"
        v-model="formParams[item.value]"
        class="br-input br-input-small"
        :class="item.className"
        type="input"
        :clearable="!item.noClearable"
        :disabled="item.disabled === true || (item.disabledFn===undefined||item.disabledFn===null?false: item.disabledFn(formParams))"
        :placeholder="item.placeholder||'请输入'+item.label"
        @clear="(event)=>{if(item.inputClear) item.inputClear(event,formParams,scopeIndex)}"
        @input="(event)=>{if(item.inputInput) item.inputInput(event,formParams,scopeIndex)}"
        @change="(event)=>{if(item.inputChange) item.inputChange(event,formParams,scopeIndex)}"
      />
      <el-select
        :ref="item.unitRef"
        v-model="formParams[item.unit]"
        class="br-select br-select-small"
        :class="item.className"
        :disabled="item.disabled === true || (item.disabledFn===undefined||item.disabledFn===null?false: item.disabledFn(formParams))"
        @change="(event)=>{if(item.unitChange) item.unitChange(event,formParams,scopeIndex)}"
      >
        <el-option
          v-for="itee in item.selectData"
          :key="itee.code"
          :label="itee.name"
          :value="itee.code"
        />
      </el-select>
    </template>

    <el-radio-group
      v-else-if="item.type==='radio'"
      :ref="item.inputRef"
      v-model="formParams[item.value]"
      :disabled="item.disabled === true || (item.disabledFn===undefined||item.disabledFn===null?false: item.disabledFn(formParams))"
      class="br-radio"
      :class="item.className"
      @change="(event)=>{if(item.inputChange) item.inputChange(event,formParams,scopeIndex)}"
    >
      <el-radio v-for="(ite,i) in item.radioList" :key="i" :disabled="item.radioDisabled === true || (item.radioDisabledFn===undefined||item.radioDisabledFn===null?false: item.radioDisabledFn(formParams,ite))" :label="ite.label">
        {{ ite.value }}
      </el-radio>
    </el-radio-group>

    <el-checkbox-group
      v-else-if="item.type==='checkbox'"
      :ref="item.inputRef"
      v-model="formParams[item.value]"
      :disabled="item.disabled === true || (item.disabledFn===undefined||item.disabledFn===null?false: item.disabledFn(formParams))"
      class="br-chekbox"
      :class="item.className"
      @change="(event)=>{if(item.inputChange) item.inputChange(event,formParams,scopeIndex)}"
    >
      <el-checkbox v-for="(ite,i) in item.radioList" :key="i" :label="ite.label">
        {{ ite.value }}
      </el-checkbox>
    </el-checkbox-group>
  </el-form-item>
</template>
<script>
import moneyUnit from './moneyUnit.vue'
export default {
  components: { moneyUnit },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: () => ('list')
    },
    formParams: {
      type: Object,
      default: () => ({})
    },
    scopeIndex: {
      type: Number,
      default: () => (0)
    }
  },
  methods: {
    dateRangeChange(e, item) {
      if (e) {
        this.formParams[item.begin] = e[0]
        this.formParams[item.end] = e[1]
      } else {
        this.formParams[item.begin] = ''
        this.formParams[item.end] = ''
      }
    },
    onLeavesClick(data, item, cb) {
      const treeSelect = this.$refs[item.inputRef]
      treeSelect['handleClose']()
      cb(data)
    },
    // 输入数字限制
    inputNumChange(e, row, value, cb) {
      e = e.replace(/[^\d.]/g, '')
      row[value] = e
      this.$forceUpdate()
      if (cb) cb(e, this.formParams, this.scopeIndex)
    },

    // 上传之前
    fileBeforeUpload(file, t) {
      const _this = this
      const size = file.size / 1024 / 1024 <= t // 限制小于tM
      if (t && !size) {
        _this.$message({
          type: 'warning',
          message: `大小不可超过${t}MB`
        })
        return size
      }
    },

    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },

    // 删除上传文件
    removeFile(row, name) {
      this.$confirm('确定删除该附件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        row[name] = null
        this.$forceUpdate()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },

    // 添加上传文件-上传成功
    addFile(res, file, name, cb) {
      if (res.code === 200) {
        this.formParams[name] = {
          fileType: file.raw.type,
          mongoId: res.data,
          oriFileName: file.raw.name
        }
        this.$forceUpdate()
        cb(res.data, file, name, this.formParams, this.scopeIndex)
      } else {
        if (res.code === 1001) {
          this.$router.replace({
            path: '/'
          })
          //   removeStore('cmpMenu')
          //   removeStore('cmpToken')
          //   removeStore('companyInfo')
          //   removeStore('cmpBtn')
          //   removeStore('cmpLoanId')
          //   removeStore('cmpType')
          window.sessionStorage.removeItem('cmpToken')
        }
        this.$message({
          type: 'info',
          message: res.msg
        })
      }
    },

    handleExceed(files, fileList, number) {
      this.$message({
        type: 'warning',
        message: `当前限制选择 ${number} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`
      })
    }
  }
}
</script>
<style scoped lang="scss">

  .upload-demo {
    display: inline-block;
  }

  .file-name {
    font-size: 14px;
    color: #409EFF;
    cursor: pointer;
    padding: 0;
  }

  .file-del-btn {
    color: #ff0000;
    line-height: 36px;
    height: 36px;
    margin-left: 4px;
    padding: 0;
  }

  .br-input-small {
    width: 120px;
    margin-right: 10px;
  }

  .br-select-small {
    width: 90px;
  }
  .el-form-item.width50{
    width:48%;
  }
  .el-form-item.width100{
    width:100%;
  }
</style>
