<template>
  <div style="margin-bottom: 10px">
    <!-- highlight-current-row是否要高亮当前行
        expand-row-keys展开行
        expand-change当用户对某一行展开或者关闭的时候会触发该事件
        show-summary是否在表尾显示合计行
        sum-text合计行第一列的文本
        summary-method自定义的合计计算方法 func
        span-method	合并行或列的计算方法
        row-class-name	行的className的回调方法，也可以使用字符串为所有行设置一个固定的className
        row-click 单击整行选中
        selection-change 全选
        -->
    <div v-if="item.needAddBtn" class="add-info-btn">
      <!-- <renderBtn v-if="item.addBtnCode" :code="item.addBtnCode">
        <el-button type="primary" class="br-btn-large br-submit-btn" :disabled="item.addBtnDisabled" @click="item.addTableList">
          {{ item.addTableTxt||'新增' }}
        </el-button>
      </renderBtn> -->
      <el-button size="small" type="primary" class="br-btn-large br-submit-btn" :disabled="item.addBtnDisabled" @click="item.addTableList">
        {{ item.addTableTxt||'新增' }}
      </el-button>
    </div>
    <el-table
      ref="tableRef"
      :border="true"
      :empty-text="item.canModify?'请 新增填写行 进行填写':'没有符合条件的数据'"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.3)"
      class="br-table"
      :data="item.dataValue||[]"
      :highlight-current-row="false"
      row-key="dataId"
      :show-summary="item.showSummary"

      :sum-text="item.sumText"
      :summary-method="item.getSummariesFunc"
      :span-method="item.spanMethodFunc"
      :row-class-name="item.rowClass"

      :stripe="item.rowClass===null"
    >
      <el-table-column
        v-if="item.hasIndex"
        label=""
        type="index"
        prop="costName"
        align="center"
        width="34"
        fixed="left"
      />

      <!--      //多选的禁用与否
              selectInit(row,index){
                  if(row.flowStatus=="已提交"){
                      return false  //不可勾选
                  }else{
                      return true  //可勾选
                  }
              }-->
      <el-table-column
        v-if="item.canSelection"
        type="selection"
        align="center"
        width="34"
        fixed="left"
        :selectable="item.selectInit"
      />
      <!--
       :align="ite.align || (ite.type==='money'||ite.type==='date'?'right':'center')"
       :header-align="ite.headerAlign || ite.type==='money'||ite.type==='date'?'right':'center'"
      -->
      <!-- {{ `${ite.isHide} 1` }} -->
      <el-table-column
        v-for="(ite,i) in dataList"
        :key="i"
        :label="ite.label"
        :prop="ite.prop"
        :show-overflow-tooltip="!item.hideOverflowTooltip"
        align="center"
        header-align="center"
        :width="ite.width"
        :min-width="ite.minWidth"
      >
        <template slot="header">
          <span v-if="ite.required" style="color: #f56c6c">*</span>{{ ite.label }}
        </template>

        <template v-if="!ite.isHide" slot-scope="scope">
          <infoTemplate v-if="!ite.isForm" :item="ite" :scope="scope" class-name="" />
          <formItemTemplate v-if="ite.isForm" :item="ite" type="table" :form-params="scope.row" :scope-index="scope.$index" />

          <slot v-if="ite.slot" :name="ite.slot" :row="scope" />
        </template>
      </el-table-column>

      <!-- <el-table-column v-if="item.hasOperate" label="操作" :width="item.operateWidth" fixed="right" align="left" header-align="left">
        <template slot-scope="scope">
          <i v-for="(opt,i) in item.operateList" v-if="opt.optFunc===undefined||opt.optFunc===null?true:opt.optFunc(scope)" :key="i">
            <renderBtn v-if="opt.btnCode" :code="opt.btnCode">
              <el-button
                size="mini"
                :class="opt.className"
                style="padding-left:4px;padding-right:4px;"
                :disabled="opt.disabledFunc===undefined||opt.disabledFunc===null?false:opt.disabledFunc(scope)"
                :type="opt.type"
                @click="opt.clickFunc(scope.row,scope.$index)"
              >
                {{ opt.label }}
              </el-button>
            </renderBtn>
            <el-button
              v-else
              size="mini"
              :class="opt.className"
              style="padding-left:4px;padding-right:4px;"
              :disabled="opt.disabledFunc===undefined||opt.disabledFunc===null?false:opt.disabledFunc(scope)"
              :type="opt.type"
              :icon="opt.icon"
              :circle="opt.isCircle"
              @click="opt.clickFunc(scope.row,scope.$index)"
            ><span v-if="!opt.icon&&!opt.isCircle">{{ opt.label }}</span></el-button>
          </i>
        </template>
      </el-table-column> -->
    </el-table>
  </div>
</template>
<script>
import infoTemplate from './infoTemplate'
import formItemTemplate from './formItemTemplate.vue'
export default {
  components: { formItemTemplate, infoTemplate },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    dataList() {
      return this.item.dataList.filter(item => !item.optFunc)
    },
    operateList() {
      return this.item.operateList.filter(item => !item.optFunc)
    }
  }
}
</script>
