<template>
  <i :class="className">
    <template v-if="!scope">
      <span v-if="item.type===''||item.type===undefined||item.type===null" :class="item.className" class="">{{ item.value }}</span>
      <span v-else-if="item.type==='money'" :class="item.className" class="">{{ item.value|formatMoney }}</span>
      <span v-else-if="item.type==='moneyWan'" :class="item.className" class="">{{ item.value|formatMoneyWan }}</span>
      <span v-else-if="item.type==='date'" :class="item.className" class="">{{ item.value|date }}</span>
      <span v-else-if="item.type==='time'" :class="item.className" class="">{{ item.value|time }}</span>
      <span v-else-if="item.type==='minute'" :class="item.className" class="">{{ item.value|minute }}</span>
      <span v-else-if="item.type==='month'" :class="item.className" class="">{{ item.value|month }}</span>
      <span v-else-if="item.type==='rate'" :class="item.className" class="">{{ item.value }}{{ item.value?'%':'' }}</span>
      <span v-else-if="item.type==='rateQian'" :class="item.className" class="">{{ item.value }}{{ item.value?'‰':'' }}</span>
      <span v-else-if="item.type==='rateYear'" :class="item.className" class="">{{ item.value }}{{ item.value?'%年化':'' }}</span>
      <span v-else-if="item.type==='code'" :class="item.className" class="">{{ $code(item.codeLabel,item.value) }}</span>
      <span v-else-if="item.type==='codeNew'" :class="item.className" class="">{{ $codeNew(item.codeNewList,item.value) }}</span>
      <span v-else-if="item.type==='childrenCode'" :class="item.className" class="">{{ $childrenCode(item.codeLabel,item.value,item.value2) }}</span>
      <span v-else-if="item.type==='file'" :class="item.className" class=" down-file" @click="item.openFile(item.fileParam,item.fileParam[item.fileId])">{{ item.value }}</span>
      <span v-else-if="item.type==='down'" :class="item.className" class="down-file" @click="item.clickDown(item)">{{ item.value }}</span>
    </template>

    <template v-else>
      <span v-if="item.type===''||item.type===undefined||item.type===null" :class="item.className">{{ scope.row[item.value] }}</span>
      <span v-else-if="item.type==='unit'" :class="item.className">{{ scope.row[item.value] }}{{ $codeNew(item.unitMap,scope.row[item.unit]) }}</span>
      <span v-else-if="item.type==='money'" :class="item.className">{{ scope.row[item.value]|formatMoney }}</span>
      <span v-else-if="item.type==='moneyWan'" :class="item.className">{{ scope.row[item.value]|formatMoneyWan }}</span>
      <span v-else-if="item.type==='date'" :class="item.className">{{ scope.row[item.value]|date }}</span>
      <span v-else-if="item.type==='time'" :class="item.className">{{ scope.row[item.value]|time }}</span>
      <span v-else-if="item.type==='minute'" :class="item.className">{{ scope.row[item.value]|minute }}</span>
      <span v-else-if="item.type==='month'" :class="item.className">{{ scope.row[item.value]|month }}</span>
      <span v-else-if="item.type==='rate'" :class="item.className">{{ scope.row[item.value] }}{{ scope.row[item.value]?'%':'' }}</span>
      <span v-else-if="item.type==='rateQian'" :class="item.className">{{ scope.row[item.value] }}{{ scope.row[item.value]?'‰':'' }}</span>
      <span v-else-if="item.type==='rateYear'" :class="item.className">{{ scope.row[item.value] }}{{ scope.row[item.value]?'%年化':'' }}</span>
      <span v-else-if="item.type==='code'" :class="item.className">{{ $code(item.codeLabel,scope.row[item.value]) }}</span>
      <span v-else-if="item.type==='codeNew'" :class="item.className">{{ $codeNew(item.codeNewList,scope.row[item.value]) }}</span>
      <span v-else-if="item.type==='childrenCode'" :class="item.className">{{ $childrenCode(item.codeLabel,scope.row[item.value],scope.row[item.value]) }}</span>
      <!--  文件是数组格式   -->
      <span v-else-if="item.type==='fileArray'" :class="item.className" class="down-file" @click="item.openFile(scope.row[item.fileParam][item.fileIndex],scope.row[item.fileParam][item.fileIndex][item.fileId])">{{ scope.row[item.fileParam]?scope.row[item.fileParam][item.fileIndex][item.value]:'' }}</span>
      <!--  文件是对象格式   -->
      <span v-else-if="item.type==='file'" :class="item.className" class="down-file" @click="item.openFile(scope.row[item.fileParam],scope.row[item.fileParam][item.fileId])">{{ scope.row[item.fileParam]?scope.row[item.fileParam][item.value]:'' }}</span>
      <span v-else-if="item.type==='down'" :class="item.className" class="down-file" @click="item.clickDown(scope.row)">{{ scope.row[item.value] }}</span>
    </template>
  </i>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    className: {
      type: String,
      default: () => ('flex1')
    },
    scope: {
      type: [Object, String], // 支持多种类型
      default: '' // 默认值
    }
  }
}
</script>

<style scoped>
  .down-file {
    cursor: pointer;
    color: #409eff;
  }
  .formInfo{
    width: 210px;
    display: inline-block;
  }
</style>

